<template>
    <article class="notice_detail_item">
        <div class="taging">
            <span class="notice_tag">공지</span>
        </div>
        <h1 class="notice_detail_title">
            {{ getCommonNoticeInfo.commonNoticeTitle }}
        </h1>
        <div class="notice_detail_date">
            {{ getCommonNoticeInfo.reigisterFormat }}
        </div>
        <div class="notice_detail_contenst" v-html="getCommonNoticeInfo.commonNoticeContents">

        </div>
        <div class="list_button_wrap">
            <button class="list_button" @click="goNoticeList">목록</button>
        </div>
    </article>
</template>
<script>
import axios from 'axios';
export default {
    name: 'NoticeDetailComponent',

    data() {
        return {
            commonNoticeInfo: {
                commonNoticeAction: "",
                commonNoticeChannel: "",
                commonNoticeContents: "",
                commonNoticeId: "",
                commonNoticePopup: "",
                commonNoticeSummary: "",
                commonNoticeTitle: "",
                commonNoticeTop: "",
                commonNoticeWriter: "",
                endDate: new Date().getTime(),
                registDate: new Date().getTime(),
                startDate: new Date().getTime()
            }
        };
    },

    created() {

        const { id } = this.$route.params;

        this.getNoticeItem(id);
    },

    mounted() {

    },

    methods: {
        goNoticeList() {
            this.$router.push({ name: "BrandNoticeList" }).catch(() => { });
        },
        clearCommonInfo() {
            this.commonNoticeInfo = {
                commonNoticeAction: "",
                commonNoticeChannel: "",
                commonNoticeContents: "",
                commonNoticeId: "",
                commonNoticePopup: "",
                commonNoticeSummary: "",
                commonNoticeTitle: "",
                commonNoticeTop: "",
                commonNoticeWriter: "",
                endDate: new Date().getTime(),
                registDate: new Date().getTime(),
                startDate: new Date().getTime()
            };
        },
        /**
         * @description 공지사항 Top List
         * @author CHOI DAE GEON
         */
        getNoticeItem(id) {
            axios
                .post('/api/menu/getCommonNoticePopupList', { nonmemberShowing: "Y" })
                .then(res => {
                    const { result } = res.data;
                    if (result.length > 0) {
                        const findItem = result.find(item => item.commonNoticeId === id);
                        if (findItem) {
                            this.commonNoticeInfo = findItem;
                        } else {
                            this.clearCommonInfo();
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.clearCommonInfo();
                });
        }
    },

    computed: {
        getCommonNoticeInfo() {
            const copyNoticeInfo = Object.assign({}, this.commonNoticeInfo);
            copyNoticeInfo.commonNoticeContents = copyNoticeInfo.commonNoticeContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>").replace(/&quot;/g, '"');

            const registerFormatDate = new Date(this.commonNoticeInfo.registDate);
            const registerYyyy = registerFormatDate.getFullYear().toString().slice(2, 4);
            const registerMm = registerFormatDate.getMonth() > 9 ? registerFormatDate.getMonth() : `0${registerFormatDate.getMonth()}`;
            const registerDate = registerFormatDate.getDate() > 9 ? registerFormatDate.getDate() : `0${registerFormatDate.getDate()}`;
            const registerStartTime = registerFormatDate.getHours() > 9 ? registerFormatDate.getHours() : `0${registerFormatDate.getHours()}`;
            const registerEndTime = registerFormatDate.getMinutes() > 9 ? registerFormatDate.getMinutes() : `0${registerFormatDate.getMinutes()}`;
            const reigisterFormat = `${registerYyyy}.${registerMm}.${registerDate} ${registerStartTime}:${registerEndTime}`;
            copyNoticeInfo.reigisterFormat = reigisterFormat;
            return copyNoticeInfo;
        }
    }
};
</script>
<style scoped>
.notice_detail_item {
    display: flex;
    flex-direction: column;
    padding-bottom: 161px;
}

.taging {
    width: 90px;
    display: flex;
    align-items: center;
}

.notice_tag {
    padding: 2px 16px;
    background: var(--ffffff-12, rgba(255, 255, 255, 0.12));
    border-radius: 100px;
    opacity: 0.97;
    font-size: 16px;
    line-height: 1.6;
    color: var(--white);
}

.notice_detail_title {
    font-size: 24px;
    color: var(--white);
    font-weight: 500;
    line-height: 32px;
    margin-top: 13px;
    padding-bottom: 32px;
}

.notice_detail_date {
    display: none;
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 24px;
}

.notice_detail_contenst {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    padding-top: 24px;
    padding-bottom: 177px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.87);
}



.list_button_wrap {
    display: flex;
    justify-content: center;
    margin-top: 62px;
}

.list_button {
    font-size: 16px;
    font-weight: 500;
    padding: 19px 75px;
    color: var(--white);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: transparent;
}

/*######################## 반응형 처리 ~500px ########################*/
@media all and (max-width: 500px) {
    .notice_tag {
        font-size: 14px;
    }

    .notice_detail_title {
        font-size: 18px;
        word-break: keep-all;

    }

    .notice_detail_date {
        display: inline-block;
        width: 100%;
    }

    .list_button_wrap {
        width: 100%;
        margin: 0 auto;
    }

    .notice_detail_contenst {
        padding-bottom: 39px;
    }

    .list_button {
        width: 100%;
        font-size: 14px;
        line-height: 30px;
        padding: 9px 0;
    }

    .notice_detail_item {
        padding-bottom: 67px;
    }
}
</style>
